import { graphql } from "gatsby"
import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectProductGroups,
  selectResponsibilityPageContent,
  selectTranslations,
} from "../graphql/selectors"
import s from "./responsibility.module.scss"

export default function ResponsibilityPage(props) {
  const { data, pageContext } = props
  const { langCode, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const { seo, statements } = selectResponsibilityPageContent(data)

  return (
    <Layout
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <section className={s.wrapper}>
        <h1 className={s.heading}>{statements[0].title}</h1>
        {statements.map((item) => {
          return (
            <p
              className={s.paragraph}
              dangerouslySetInnerHTML={{ __html: item.description }}
              key={item.description}
            ></p>
          )
        })}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ResponsibilityPage($locale: String) {
    ...LayoutFragment
    ...ResponsibilityPageFragment
  }
`
